import React, { useEffect, useState } from "react"
import { StaticImage } from "gatsby-plugin-image"

import MaterialTable from "material-table"
import Icons from "../hooks/materialTableIcons"
import Rating from '@material-ui/lab/Rating';
import Hash from "../images/svg/hash-pattern.svg"

import WindowPatternTop from "../images/svg/window-pattern-top.svg"
import WindowPatternBottom from "../images/svg/window-pattern-bottom.svg"
import SunsetPatternTop from "../images/svg/sunset-pattern-top.svg"

import WindowSlider from "../components/window-slider"



import Window from "../images/svg/plane-window-dark.svg"
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { TimelineLite, Bounce, Power4 } from "gsap/all";

const columns = [
  { title: "#", field: "id" },
  { title: "Airline", field: "airline" },
  { title: "Entertainment", field: "entertainment", type: "numeric" },
  { title: "Amenities", field: "amenities", type: "numeric" },
  { title: "Comfort", field: "comfort", type: "numeric" },
  { title: "COVID Safe", field: "covid", type: "numeric" },
  { title: "Food", field: "food", type: "numeric" },
  { title: "Drink", field: "drink", type: "numeric" },
  {
    title: "Total Rating", field: "total", type: "numeric"
    // render: rowData => {
    //   return <Rating name="hover-feedback" value={rowData.total} readOnly />;
    // },
  }
];

const dataEconomy = [
  {
    id: 1,
    airline: "Alaska Airlines",
    entertainment: 60.0,
    amenities: 100.0,
    comfort: 65.0,
    covid: 40.0,
    food: 41.3,
    drink: 94.5,
    total: 66.8
  },
  {
    id: 2,
    airline: "JetBlue",
    entertainment: 45.0,
    amenities: 90.0,
    comfort: 90.0,
    covid: 40.0,
    food: 50.0,
    drink: 85.2,
    total: 66.7
  },
  {
    id: 3,
    airline: "Delta",
    entertainment: 62.8,
    amenities: 80.0,
    comfort: 62.5,
    covid: 40.0,
    food: 51.3,
    drink: 77.8,
    total: 62.4
  },
  {
    id: 4,
    airline: "United",
    entertainment: 50.0,
    amenities: 60.0,
    comfort: 32.5,
    covid: 40.0,
    food: 55.0,
    drink: 66.7,
    total: 50.7
  },
  {
    id: 5,
    airline: "American Airlines",
    entertainment: 52.8,
    amenities: 70.0,
    comfort: 40.0,
    covid: 10.0,
    food: 43.8,
    drink: 74.1,
    total: 48.4
  },
  {
    id: 6,
    airline: "Hawaiian Airlines",
    entertainment: 14.5,
    amenities: 0.0,
    comfort: 45.0,
    covid: 40.0,
    food: 90.0,
    drink: 100.0,
    total: 48.2
  },
  {
    id: 7,
    airline: "Sun Country",
    entertainment: 60.0,
    amenities: 0.0,
    comfort: 35.0,
    covid: 0.0,
    food: 78.8,
    drink: 92.6,
    total: 44.4
  },
  {
    id: 8,
    airline: "Southwest",
    entertainment: 38.3,
    amenities: 0.0,
    comfort: 35.0,
    covid: 40.0,
    food: 30.0,
    drink: 100.0,
    total: 40.6
  },
  {
    id: 9,
    airline: "Frontier",
    entertainment: 0.0,
    amenities: 0.0,
    comfort: 40.0,
    covid: 10.0,
    food: 55.0,
    drink: 43.4,
    total: 24.7
  },
  {
    id: 10,
    airline: "Spirit",
    entertainment: 0.0,
    amenities: 0.0,
    comfort: 45.0,
    covid: 10.0,
    food: 57.5,
    drink: 8.5,
    total: 20.2
  },
  {
    id: 11,
    airline: "Allegiant",
    entertainment: 0.0,
    amenities: 0.0,
    comfort: 25.0,
    covid: 40.0,
    food: 12.5,
    drink: 18.1,
    total: 15.9
  }
];

const dataFirstClass = [
  {
    id: 1,
    airline: "Alaska Airlines",
    entertainment: 54.2,
    amenities: 100,
    comfort: 83.3,
    covid: 25,
    food: 50,
    drink: 75,
    total: 64.6
  },
  {
    id: 2,
    airline: "Delta",
    entertainment: 41.7,
    amenities: 50,
    comfort: 41.7,
    covid: 25,
    food: 100,
    drink: 91.7,
    total: 58.3
  },
  {
    id: 3,
    airline: "United",
    entertainment: 37.5,
    amenities: 75,
    comfort: 33.3,
    covid: 25,
    food: 75,
    drink: 91.7,
    total: 56.3
  },
  {
    id: 4,
    airline: "American Airlines",
    entertainment: 41.7,
    amenities: 25,
    comfort: 58.3,
    covid: 0,
    food: 62.5,
    drink: 83.3,
    total: 45.1
  },
  {
    id: 5,
    airline: "Hawaiian Airlines",
    entertainment: 8.3,
    amenities: 0,
    comfort: 33.3,
    covid: 25,
    food: 87.5,
    drink: 66.7,
    total: 36.8
  }
];


const Section3 = ({ back, isMobile, pageType }) => {
  const [activeWindowSlider, setActiveWindowSlider] = useState(1);

  useEffect(() => {
    const window = document.querySelector("#window #shutter");

    document.querySelector('table thead th:nth-of-type(1) .MuiTableSortLabel-root').click()

    /** If we are returning from the sights pageType */
    // if (back !== null) {
    //   //document.querySelector('#window').style.zIndex = 9999;
    //   //document.querySelector('#window').style.left = "50%";
    //   document.querySelector('#section3 > div').style.height = "120vh";
    //   document.querySelector('#section3 > div').style.marginTop = "-20vh";
    //   //document.querySelector('#window').style.transform = "scale(2) translate(-50%, 0)";
    //   document.querySelector('#section3 .content').style.opacity = 0;
    //   document.querySelector('#window #shutter #shutterGroup').style.transform = "translateY(-90%)";


    //   setTimeout(() => {
    //     let animationIn = new TimelineLite();
    //     animationIn
    //       // .to("#window", 1, {
    //       //   scale: 1,
    //       //   ease: "power4.out",
    //       // })
    //       .to("#section3 > div", 0.25, {
    //         height: 'auto',
    //         marginTop: 0
    //       })
    //       .to("#section3 .content", 0.25, {
    //         opacity: 1
    //       })
    //       .to("#window #shutter #shutterGroup", 0.25, {
    //         y: '0%',
    //       });
    //   }, 500);
    // }


    window.addEventListener("mouseover", (e) => {
      //window.querySelector("#shutter").classList.add("up");
      window.querySelector('#shutter #shutterGroup').classList.add('up');
    });
    window.addEventListener("mouseleave", (e) => {
      //window.querySelector("#shutter ").classList.remove("up");
      window.querySelector('#shutter #shutterGroup').classList.remove('up');
    });
    window.addEventListener("click", (e) => {
      let animation = new TimelineLite();
      animation
        .to("#window #shutter #shutterGroup", 0.25, {
          y: '-80%',
        })
      // .to("#section3 .content", 0.25, {
      //   opacity: 0
      // })
      // .to("#section3 > div", 0.25, {
      //   height: '120vh',
      //   marginTop: "-20vh"
      // })
      // .to("#window", 1, {
      //   zIndex: 9999,
      //   scale: 2,
      //   ease: "power4.in",
      // });

      //setTimeout(() => {
      animation.eventCallback("onComplete", function () {
        document.getElementById("goToSights").click();
      });
      // }, 1500)

    });
  }, [isMobile, back, activeWindowSlider]);

  return (

    <div
      id="section3"
      className="flex flex-col relative w-full h-auto items-center"
    >

      <div className="w-full h-auto mb-0 lg:mb-10 relative flex flex-col lg:flex-row items-start lg:items-center justify-center gap-x-10 py-20 lg:py-20">
        <WindowPatternTop />
        <StaticImage
          alt="Window"
          src={"../images/plane-window-background-banner-desktop-dark.jpg"}
          formats={["auto", "webp", "avif"]}
          loading="lazy"
          objectFit="cover"
          objectPosition="50% 50%"
          layout="constrained"
          placeholder="blurred"
          className="absolute top-0"
          style={{
            position: "absolute",
            height: "100%"
          }}
        />
        <div
          className="w-full lg:w-1/3 flex items-center justify-center lg:justify-end relative order-2 lg:order-1" style={{
            minHeight: "400px"
          }}
        >
          <Window />
          <AniLink fade to="sights" id="goToSights" className="hidden">
            &nbsp;
          </AniLink>
        </div>


        <div className="content w-full lg:w-2/3 max-w-xs lg:max-w-md mx-auto lg:ml-0 lg:mr-20 order-1 lg:order-2">
          <span className="block text-3xl lg:text-5xl text-white opacity-10 font-oswald text-lighter uppercase -mb-4 -ml-4 tracking-wide">Views</span>
          <h2 className="text-white font-bold font-oswald text-xl lg:text-2xl uppercase mb-3 leading-none">The best sights you'll<br />See from your plane window</h2>
          {pageType == 'economy' ? (
            <p className="text-sm text-white font-regular">It's not just physical comfort that makes a difference to your flight - the views can take any flight and turn it into a thing of wonder. But which routes from which airlines offer the most stunning scenery for travelers to feast their eyes on?</p>
          ) : (
            <p className="text-sm text-white font-regular">That extra leg room and secluded section of the cabin isn't the only thing that makes a flight great - the views can take the journey from ordinary to unforgettable. But which airlines are flying over the routes with the most stunning scenery for first-class flyers to feast their eyes on?</p>

          )}
        </div>
        <WindowPatternBottom />
      </div>
      <div id="cardsSection" className="w-full max-w-xs lg:max-w-6xl mx-auto h-auto mb-0 lg:mb-10 relative flex items-center justify-center grid grid-cols-2 lg:grid-cols-10">
        <div className="hidden lg:flex col-span-2 gap-x-5">
          <div className="py-10">
            <div className="h-full flex flex-col justify-center items-end text-right">
              <div className={`flex items-center h-8 text-roboto ${activeWindowSlider == 1 ? 'text-up-blue font-bold' : 'text-light-grey'} uppercase tracking-loose text-sm cursor-pointer`} onClick={() => {
                setActiveWindowSlider(1);
                document.querySelector('#windowSlider').scrollIntoView({
                  block: 'center',
                  behavior: 'smooth'
                })
                document.querySelector('#windowSlider .swiper-pagination > .swiper-pagination-bullet:nth-of-type(1)').click();
              }}>Comfort</div>
              {/* <div className={`flex items-center h-8 text-roboto ${activeWindowSlider == 0 ? 'text-up-blue font-bold' : 'text-light-grey'} uppercase tracking-loose text-sm cursor-pointer`} onClick={() => {
                setActiveWindowSlider(0);
                document.querySelector('#section3').scrollIntoView({
                  //block: 'center',
                  behavior: 'smooth'
                })
              }}>Views</div> */}
              <div className={`flex items-center h-8 text-roboto ${activeWindowSlider == 2 ? 'text-up-blue font-bold' : 'text-light-grey'} uppercase tracking-loose text-sm cursor-pointer`} onClick={() => {
                setActiveWindowSlider(2);
                document.querySelector('#windowSlider').scrollIntoView({
                  block: 'center',
                  behavior: 'smooth'
                })
                document.querySelector('#windowSlider .swiper-pagination > .swiper-pagination-bullet:nth-of-type(2)').click();
              }}>Entertainment</div>
              <div className={`flex items-center h-8 text-roboto ${activeWindowSlider == 3 ? 'text-up-blue font-bold' : 'text-light-grey'} uppercase tracking-loose text-sm cursor-pointer`} onClick={() => {
                setActiveWindowSlider(3);
                document.querySelector('#windowSlider').scrollIntoView({
                  block: 'center',
                  behavior: 'smooth'
                })
                document.querySelector('#windowSlider .swiper-pagination > .swiper-pagination-bullet:nth-of-type(3)').click();
              }}>Refreshments</div>
              <div className={`flex items-center h-8 text-roboto ${activeWindowSlider == 4 ? 'text-up-blue font-bold' : 'text-light-grey'} uppercase tracking-loose text-sm cursor-pointer`} onClick={() => {
                setActiveWindowSlider(4);
                document.querySelector('#windowSlider').scrollIntoView({
                  block: 'center',
                  behavior: 'smooth'
                })
                document.querySelector('#windowSlider .swiper-pagination > .swiper-pagination-bullet:nth-of-type(4)').click();
              }}>Covid Safe</div>
              {/* <div className={`flex items-center h-8 text-roboto ${activeWindowSlider == 5 ? 'text-up-blue font-bold' : 'text-light-grey'} uppercase tracking-loose text-sm`} onClick={() => {
                setActiveWindowSlider(5);
                document.querySelector('#windowSlider .swiper-pagination > .swiper-pagination-bullet:nth-of-type(5)').click();
              }}>Value</div> */}
            </div>
          </div>
          <div className="py-10 relative">
            <div className="absolute left-1/2 transform -translate-x-1/2" style={{
              width: "1px",
              height: "60px",
              top: "-50px",
              background: "rgba(20,20,20,0.3)"
            }}></div>
            <div className="h-full flex flex-col justify-center items-center">
              <div className="flex items-center h-8 cursor-pointer" onClick={() => {
                setActiveWindowSlider(1);
                document.querySelector('#windowSlider').scrollIntoView({
                  block: 'center',
                  behavior: 'smooth'
                })
                document.querySelector('#windowSlider .swiper-pagination > .swiper-pagination-bullet:nth-of-type(1)').click();
              }}>
                {activeWindowSlider == 1 ? (
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
                    <circle cx="7.5" cy="7.5" r="7.5" fill="#08466b" />
                    <circle cx="2.5" cy="2.5" r="2.5" transform="translate(5 5)" fill="#fff" />
                  </svg>

                ) : (
                  <svg xmlns="http://www.w3.org/2000/svg" width="5" height="5" viewBox="0 0 5 5">
                    <path d="M2.5,0A2.5,2.5,0,1,1,0,2.5,2.5,2.5,0,0,1,2.5,0Z" fill="rgba(20,20,20,0.3)" />
                  </svg>
                )
                }
              </div>
              {/* <div className="flex items-center h-8 cursor-pointer" onClick={() => {
                setActiveWindowSlider(0);
                document.querySelector('#section3').scrollIntoView({
                  //block: 'center',
                  top: -200,
                  behavior: 'smooth'
                })
              }}>
                {activeWindowSlider == 0 ? (
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
                    <circle cx="7.5" cy="7.5" r="7.5" fill="#08466b" />
                    <circle cx="2.5" cy="2.5" r="2.5" transform="translate(5 5)" fill="#fff" />
                  </svg>

                ) : (
                  <svg xmlns="http://www.w3.org/2000/svg" width="5" height="5" viewBox="0 0 5 5">
                    <path d="M2.5,0A2.5,2.5,0,1,1,0,2.5,2.5,2.5,0,0,1,2.5,0Z" fill="rgba(20,20,20,0.3)" />
                  </svg>
                )
                }
              </div> */}
              <div className="flex items-center h-8 cursor-pointer" onClick={() => {
                setActiveWindowSlider(2);
                document.querySelector('#windowSlider').scrollIntoView({
                  block: 'center',
                  behavior: 'smooth'
                })
                document.querySelector('#windowSlider .swiper-pagination > .swiper-pagination-bullet:nth-of-type(2)').click();
              }}>
                {activeWindowSlider == 2 ? (
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
                    <circle cx="7.5" cy="7.5" r="7.5" fill="#08466b" />
                    <circle cx="2.5" cy="2.5" r="2.5" transform="translate(5 5)" fill="#fff" />
                  </svg>

                ) : (
                  <svg xmlns="http://www.w3.org/2000/svg" width="5" height="5" viewBox="0 0 5 5">
                    <path d="M2.5,0A2.5,2.5,0,1,1,0,2.5,2.5,2.5,0,0,1,2.5,0Z" fill="rgba(20,20,20,0.3)" />
                  </svg>
                )
                }
              </div>
              <div className="flex items-center h-8 cursor-pointer" onClick={() => {
                setActiveWindowSlider(3);
                document.querySelector('#windowSlider').scrollIntoView({
                  block: 'center',
                  behavior: 'smooth'
                })
                document.querySelector('#windowSlider .swiper-pagination > .swiper-pagination-bullet:nth-of-type(3)').click();
              }}>
                {activeWindowSlider == 3 ? (
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
                    <circle cx="7.5" cy="7.5" r="7.5" fill="#08466b" />
                    <circle cx="2.5" cy="2.5" r="2.5" transform="translate(5 5)" fill="#fff" />
                  </svg>

                ) : (
                  <svg xmlns="http://www.w3.org/2000/svg" width="5" height="5" viewBox="0 0 5 5">
                    <path d="M2.5,0A2.5,2.5,0,1,1,0,2.5,2.5,2.5,0,0,1,2.5,0Z" fill="rgba(20,20,20,0.3)" />
                  </svg>
                )
                }
              </div>
              <div className="flex items-center h-8 cursor-pointer" onClick={() => {
                setActiveWindowSlider(4);
                document.querySelector('#windowSlider').scrollIntoView({
                  block: 'center',
                  behavior: 'smooth'
                })
                document.querySelector('#windowSlider .swiper-pagination > .swiper-pagination-bullet:nth-of-type(4)').click();
              }}>
                {activeWindowSlider == 4 ? (
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
                    <circle cx="7.5" cy="7.5" r="7.5" fill="#08466b" />
                    <circle cx="2.5" cy="2.5" r="2.5" transform="translate(5 5)" fill="#fff" />
                  </svg>

                ) : (
                  <svg xmlns="http://www.w3.org/2000/svg" width="5" height="5" viewBox="0 0 5 5">
                    <path d="M2.5,0A2.5,2.5,0,1,1,0,2.5,2.5,2.5,0,0,1,2.5,0Z" fill="rgba(20,20,20,0.3)" />
                  </svg>
                )
                }
              </div>
              {/* <div className="flex items-center h-8 cursor-pointer">
                {activeWindowSlider == 5 ? (
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
                    <circle cx="7.5" cy="7.5" r="7.5" fill="#08466b" />
                    <circle cx="2.5" cy="2.5" r="2.5" transform="translate(5 5)" fill="#fff" />
                  </svg>
                  
                ) : (
                  <svg xmlns="http://www.w3.org/2000/svg" width="5" height="5" viewBox="0 0 5 5">
                    <path d="M2.5,0A2.5,2.5,0,1,1,0,2.5,2.5,2.5,0,0,1,2.5,0Z" fill="rgba(20,20,20,0.3)" />
                  </svg>
                )
                }
              </div> */}
            </div>
            <div className="absolute left-1/2 transform -translate-x-1/2" style={{
              width: "1px",
              height: "60px",
              bottom: "-50px",
              background: "rgba(20,20,20,0.3)"
            }}></div>
          </div>
        </div>
        <div className="col-span-2 lg:col-span-8 overflow-hidden pr-0 lg:pr-20">
          {pageType == 'economy' ? (
            <WindowSlider data={dataEconomy} pageType={pageType} />
          ) : (
            <WindowSlider data={dataFirstClass} pageType={pageType} />
          )}
        </div>

      </div>
      <div className="w-full h-10 lg:h-auto py-10 relative full-width-banner">
        <SunsetPatternTop />
        {pageType == 'economy' ? (
          <StaticImage
            alt="Window"
            src={"../images/sunset.jpg"}
            formats={["auto", "webp", "avif"]}
            loading="lazy"
            objectFit="cover"
            objectPosition="50% 50%"
            layout="constrained"
            placeholder="blurred"
            className="h-full lg:h-auto"
          />
        ) : (
          <StaticImage
            alt="Window"
            src={"../images/sunset-first-class.jpg"}
            formats={["auto", "webp", "avif"]}
            loading="lazy"
            objectFit="cover"
            objectPosition="50% 50%"
            layout="constrained"
            placeholder="blurred"
            className="h-full lg:h-auto"
          />
        )
        }
        <WindowPatternBottom />
      </div>

      <div id="tableData" className="container my-auto w-100 flex flex-col lg:flex-row justify-center gap-x-10 mt-0 lg:mt-10">
        {pageType == 'economy' ? (
          <div className="w-full max-w-xl text-center">
            <h2 className="uppercase font-bold text-xl lg:text-3xl text-black mb-3">Economy airlines index</h2>
            <p className="text-sm font-regular">
              Travelling on a budget doesn't always mean sacrificing luxury. We've looked into which airlines are offering the best in-flight experience for economy tickets. Next time you hop on a plane, the journey will be just as good as the destination.
            </p>
          </div>
        ) : (
          <div className="w-full max-w-xl text-center">
            <h2 className="uppercase font-bold text-xl lg:text-3xl text-black mb-3">First class airlines index</h2>
            <p className="text-sm font-regular">
              When you're paying extra to fly first class, you want to make the most of your money. That's why we've discovered which airlines offer the most to their first-class customers, to make sure your first-class experience is the best one possible.
            </p>
          </div>
        )}
      </div>
      <div className="container mt-10">
        <span className="text-light-grey mb-3 text-center block mx-auto">Index scores out of 100</span>
        {pageType == 'economy' ? (
          <MaterialTable
            icons={Icons}
            options={{
              search: false,
              toolbar: false,
              emptyRowsWhenPaging: false,
              pageTypeSize: 11,
              paging: false,
              order: "id",
            }}
            columns={columns}
            data={dataEconomy}
          />
        ) : (
          <MaterialTable
            icons={Icons}
            options={{
              search: false,
              toolbar: false,
              emptyRowsWhenPaging: false,
              pageTypeSize: 11,
              paging: false,
            }}
            columns={columns}
            data={dataFirstClass}
          />
        )
        }
      </div>
    </div>
  );
};

export default Section3;
