import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import Cards from "../components/cards"

export default function WindowSlider({ data, pageType }) {
    const top5Economy = [
        {
            name: "Comfort",
            headline: "THE MOST COMFORTABLE IN-FLIGHT EXPERIENCES",
            body: "On all flights, but long ones even moreso, comfort is key. Before you book your next flight, choose an airline that offers the most comfortable in-flight experience, taking into account everything from leg-room to head rests.",
            data: data.sort((a, b) => b.comfort - a.comfort).slice(0, 5)
        },
        {
            name: "Entertainment",
            headline: "THE AIRLINES WITH THE BEST ENTERTAINMENT",
            body: "Whether it's an inbuilt screen with the latest releases, or an app complete with games and TV shows, many airlines offer great entertainment to keep you occupied on your flight. But which ones offer the best range of eye-catching, time-passing amusement?",
            data: data.sort((a, b) => b.entertainment - a.entertainment).slice(0, 5)
        },
        {
            name: "Refreshments",
            headline: "THE SKY'S MOST RAVISHING REFRESHMENTS",
            body: "From ice cold drinks to full meals, refreshments on your plane can be a great part of the in-flight experience. We've looked into which airlines will give you the most restaurant-worthy refreshments to give your next flight a taste of luxury.",
            data: data.sort((a, b) => b.drink - a.drink).slice(0, 5)
        },
        {
            name: "Covid safe",
            headline: "THE MOST COVID-SAFE FLIGHTS ON OFFER",
            body: "The Covid-19 pandemic made flying a slightly more stressful experience than before, with plenty of forms to fill out and seats to sanitize. However, some airlines are wiping the floor with others when it comes to how much they're protecting their passengers from the virus.",
            data: data.sort((a, b) => b.covid - a.covid).slice(0, 5)
        },
        // {
        //     name: "Value",
        //     headline: "THE BEST VALUE IN-FLIGHT EXPERIENCE",
        //     body: "You don't always have to pay a premium to have a premium in-flight experience. Some airlines are offering passengers a great experience for a comparatively low cost, so make sure you're choosing a great one next time you take a flight.",
        //     data: []
        // }
    ]

    const top5FirstClass = [
        {
            name: "Comfort",
            headline: "THE MOST COMFORTABLE IN-FLIGHT EXPERIENCES",
            body: "Flying first-class is especially exciting on a longer haul flight, and comfort is increasingly key the more hours your journey will span. So, before you book your first-class experience, make sure you're going to be flying in the most plush setup possible.",
            data: data.sort((a, b) => b.comfort - a.comfort).slice(0, 5)
        },
        {
            name: "Entertainment",
            headline: "THE AIRLINES WITH THE BEST ENTERTAINMENT",
            body: "You can't spend the whole flight simply admiring the first-class seats - the in-flight entertainment systems will come in very handy after it all sinks in. Whether you want to enjoy the newest releases or spend your journey reliving the best shows of your childhood, some airlines have much better time-passing amusement than others.",
            data: data.sort((a, b) => b.entertainment - a.entertainment).slice(0, 5)
        },
        {
            name: "Refreshments",
            headline: "THE SKY'S MOST RAVISHING REFRESHMENTS",
            body: "The refreshments and service in first-class is a huge draw for many. From ice cold drinks to gourmet meals, many airlines go above and beyond to give first-class passengers a literal taste of luxury - but which ones are leading the charge?",
            data: data.sort((a, b) => b.drink - a.drink).slice(0, 5)
        },
        {
            name: "Covid safe",
            headline: "THE MOST COVID-SAFE FLIGHTS ON OFFER",
            body: "The Covid-19 pandemic made flying a slightly more stressful experience than before, with plenty of forms to fill out and seats to sanitize. However, some airlines are wiping the floor with others when it comes to how much they're protecting their passengers from the virus.",
            data: data.sort((a, b) => b.covid - a.covid).slice(0, 5)
        },
        // {
        //     name: "Value",
        //     headline: "THE BEST VALUE IN-FLIGHT EXPERIENCE",
        //     body: "You don't always have to pay a premium to have a premium in-flight experience. Some airlines are offering passengers a great experience for a comparatively low cost, so make sure you're choosing a great one next time you take a flight.",
        //     data: []
        // }
    ]

    return (
        <>
            <Swiper
                id="windowSlider"
                allowTouchMove={false}
                spaceBetween={200}
                modules={[Pagination]}
                pagination={{
                    clickable: true
                }}
                className="overflow-visible m-0"
                style={{
                    overflow: 'visible',
                    margin: 0,
                }}
            >
                {pageType == 'economy' ? (
                    top5Economy.map((slide) => {
                        return (<SwiperSlide className="pl-0 lg:pl-5">
                            <div className="grid grid-cols-1 lg:grid-cols-8 gap-x-10">
                                <div className="col-span-1 lg:col-span-4">
                                    <span className="block text-3xl lg:text-5xl text-black opacity-10 font-oswald text-lighter uppercase -mb-4 -ml-4 tracking-wide">{slide.name}</span>
                                    <h2 className="text-black font-bold font-oswald text-xl lg:text-2xl uppercase mb-3 leading-none">{slide.headline}</h2>
                                    <p className="text-sm text-black font-regular">{slide.body}</p>
                                </div>
                                <div className="col-span-1 lg:col-span-4 pb-0 lg:pb-20 flex justify-center">
                                    <Cards type={slide.name} top5={slide.data} />
                                </div>
                            </div>
                        </SwiperSlide>)
                    })
                ) : (
                    top5FirstClass.map((slide) => {
                        return (<SwiperSlide className="pl-0 lg:pl-5">
                            <div className="grid grid-cols-1 lg:grid-cols-8 gap-x-10">
                                <div className="col-span-1 lg:col-span-4">
                                    <span className="block text-3xl lg:text-5xl text-black opacity-10 font-oswald text-lighter uppercase -mb-4 -ml-4 tracking-wide">{slide.name}</span>
                                    <h2 className="text-black font-bold font-oswald text-xl lg:text-2xl uppercase mb-3 leading-none">{slide.headline}</h2>
                                    <p className="text-sm text-black font-regular">{slide.body}</p>
                                </div>
                                <div className="col-span-1 lg:col-span-4 pb-0 lg:pb-20 flex justify-center">
                                    <Cards type={slide.name} top5={slide.data} pageType={pageType} />
                                </div>
                            </div>
                        </SwiperSlide>)
                    })
                )
                }
            </Swiper>
        </>
    );
}