import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Section2 = ({ isMobile, pageType }) => {
  return (
    <div
      id="section2"
      className="flex flex-col relative w-full h-auto items-center pt-0 lg:pt-10 pb-10"
    >
      <div className="container flex flex-col items-center my-auto w-100">
        <div className="w-full max-w-4xl grid grid-cols-1 lg:grid-cols-5 gap-x-10 flex items-center">
          <div className="col-span-1 lg:col-span-2 order-2 lg:order-1">
            {pageType == 'economy' ? (
              <StaticImage
                alt="Aeroplane window"
                src={"../images/best-economy-experiences-image-1.jpg"}
                formats={["auto", "webp", "avif"]}
                loading="lazy"
                objectFit="cover"
                objectPosition="50% 50%"
                layout="constrained"
                placeholder="blurred"
                className="w-overflow"
              />
            ) : (
              <StaticImage
                alt="Aeroplane window"
                src={"../images/best-first-class-experiences-image-1.jpg"}
                formats={["auto", "webp", "avif"]}
                loading="lazy"
                objectFit="cover"
                objectPosition="50% 50%"
                layout="constrained"
                placeholder="blurred"
                className="w-overflow"
              />
            )}
          </div>
          {pageType == 'economy' ? (
            <div className="col-span-1 lg:col-span-3 pb-10 order-1 lg:order-2">
              <h2 className="uppercase font-bold text-xl lg:text-3xl text-black mb-3 leading-none">Put the fun<br className="hidden lg:block" />back into flying</h2>
              <p className="text-sm font-regular">
                Traveling by plane can be an exciting adventure, but it can also be a hectic one, making the journey more painful than pleasant. Not any more.
              </p>
              <p className="text-sm font-regular">At Upgraded Points, we've discovered which airlines are offering the best perks and creature comforts on the market, to bring the fun back to flying.
              </p>
            </div>
          ) : (
            <div className="col-span-1 lg:col-span-3 pb-16 order-1 lg:order-2">
              <h2 className="uppercase font-bold text-xl lg:text-3xl text-black mb-3 leading-none">Put the fun<br className="hidden lg:block" />back into flying</h2>
              <p className="text-sm font-regular">
                Spending that extra cash on a first-class ticket is something many don't get to do too often, so when you do decide to splurge, it's important to know that the cost is going to be worth it. We're talking free drinks, warm towels, more legroom than you know what to do with: a first-class flight can be the height of luxury.
              </p>
              <p className="text-sm font-regular">But which airlines are going to give you the most bang for your buck? At Upgraded Points, we've discovered which US airlines are offering the best first-class experience, so you can be sure you'll get the Royal treatment next time you decide to upgrade.
              </p>
            </div>
          )}
        </div>
        <div className="hidden md:flex w-full max-w-4xl flex justify-end items-center -ml-10">
          {pageType == 'economy' ? (
            <StaticImage
              alt="Aeroplane wing"
              src={"../images/best-economy-experiences-image-2.jpg"}
              formats={["auto", "webp", "avif"]}
              loading="lazy"
              objectFit="contain"
              objectPosition="50% 50%"
              layout="constrained"
              height="350"
              placeholder="blurred"
              className="-mt-10"
            />
          ) : (
            <StaticImage
              alt="Aeroplane wing"
              src={"../images/best-first-class-experiences-image-2.jpg"}
              formats={["auto", "webp", "avif"]}
              loading="lazy"
              objectFit="contain"
              objectPosition="50% 50%"
              layout="constrained"
              height="350"
              placeholder="blurred"
              className="-mt-10"
            />
          )
          }
        </div>
        <div className="w-full max-w-4xl grid grid-cols-1 lg:grid-cols-5 gap-x-10 flex items-center -mt-36">
          {pageType == 'economy' ? (
            <div className="col-span-1 lg:col-span-2 flex justiyf-end order-2 lg:order-1 w-overflow">
              <StaticImage
                alt="Aeroplane window"
                src={"../images/best-economy-experiences-image-3.jpg"}
                formats={["auto", "webp", "avif"]}
                loading="lazy"
                objectFit="cover"
                objectPosition="50% 50%"
                layout="constrained"
                placeholder="blurred"
                width="300"
                className="mt-5 md:mt-20 lg:mt-0 ml-auto mr-0 mx-auto md:ml-auto md:mr-0 w-full"
                style={{
                  zIndex: 3
                }}
              />
            </div>
          ) : (
            <div className="col-span-1 lg:col-span-2 flex justiyf-end order-2 lg:order-1 w-overflow">
              <StaticImage
                alt="Aeroplane window"
                src={"../images/best-first-class-experiences-image-3.jpg"}
                formats={["auto", "webp", "avif"]}
                loading="lazy"
                objectFit="cover"
                objectPosition="50% 50%"
                layout="constrained"
                placeholder="blurred"
                width="300"
                className="mt-5 md:mt-20 lg:mt-0 mx-auto md:ml-auto md:mr-0 w-full"
                style={{
                  zIndex: 3
                }}
              />


            </div>
          )}
          {pageType == 'economy' ? (
            <div className="col-span-1 lg:col-span-3 pt-48 pb-10 order-1 lg:order-2">
              <h2 className="uppercase font-bold text-xl lg:text-3xl text-black mb-3 leading-none">How do you measure pleasure?</h2>
              <p className="text-sm font-regular">
                When you're flying on a budget, you want to make your money stretch as far as possible - just because you're not spending the big bucks doesn't mean you have to sacrifice your comfort.
              </p><p className="text-sm font-regular">We've looked at the most popular US airlines and collected the data to find out how each of them score on our index of in-flight pleasure. The airlines have been scored on entertainment, amenities, comfort, COVID measures, quality of food and variety of drinks on offer.
              </p><p className="text-sm font-regular">Discover the best in-flight economy experiences on the market, and choose an airline that will treat you right on your next trip. Rest assured: the next time you hop on a plane, the journey will be just as good as the destination.
              </p>
            </div>
          ) : (
            <div className="col-span-1 lg:col-span-3 pt-48 pb-10 order-1 lg:order-2">
              <h2 className="uppercase font-bold text-xl lg:text-3xl text-black mb-3 leading-none">Finding a first-class flight</h2>
              <p className="text-sm font-regular">
                When you're paying extra to fly first-class, you want to make the most of your money. That's why we've discovered which airlines offer the most to their first-class customers, to make sure you're offered all the pampering possible.
              </p><p className="text-sm font-regular">We've looked at the most popular US airlines and collected the data to find out how each of them score on our index of in-flight pleasure. The airlines have been scored on entertainment, amenities, comfort, COVID measures, quality of food and variety of drinks on offer.
              </p><p className="text-sm font-regular">Find the best first-class experiences on the market, and choose an airline that will treat you right on your next trip. Rest assured: the next time you hop on a plane, the journey will be just as good as the destination.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Section2;
