import React from "react";

const SideMenu = ({ setPageType }) => {
    const url = typeof window !== 'undefined' ? window.location.pathname : '';
    return (
        <div id="sideMenu" className="fixed w-auto h-auto right-5 top-1/2 lg:top-1/2 origin-right transform rotate-90 -translate-y-1/2 bg-white z-30 rounded-b-lg overflow-hidden shadow-lg">
            <div className="transform flex">
                <div className={`${url == '/' ? 'bg-up-blue' : 'bg-white'} px-4 py-2 cursor-pointer`}>

                    <span onClick={() => {
                        document.querySelector('#overlay').classList.add('active');
                        setTimeout(() => {
                            document.querySelector('#overlay').classList.remove('active');
                            setPageType('economy')
                            document.querySelector('table thead th:nth-of-type(1) .MuiTableSortLabel-root').click()
                        }, 750)

                    }} className={`${url == '/' ? 'text-white' : 'text-up-blue'} font-bold font-oswald text-base tracking-tight cursor`}>Economy Experience</span>

                </div>
                <div className={`${url == '/' ? 'bg-white' : 'bg-up-blue'} px-4 py-2 cursor-pointer`}>

                    <span onClick={() => {
                        document.querySelector('#overlay').classList.add('active');
                        setTimeout(() => {
                            document.querySelector('#overlay').classList.remove('active');
                            setPageType('first-class')
                            document.querySelector('table thead th:nth-of-type(1) .MuiTableSortLabel-root').click()
                        }, 750)

                    }} className={`${url == '/' ? 'text-up-blue' : 'text-white'} font-bold font-oswald text-base tracking-tight cursor`}>First Class Experience</span>

                </div>
            </div >
        </div >
    )
}

export default SideMenu