import React from "react";
import Logo from "../components/logo";
import SocialIcons from "../components/social-icons";
import { useStaticQuery, graphql } from "gatsby";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

export default function Footer() {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          baseUrl
          siteUrl
        }
      }
    }
  `);
  return (
    <footer className="flex p-6 lg:p-5 items-center justify-center bg-blue">
      <div className="container flex flex-col md:flex-row items-center my-5 mx-0 lg:mx-10">
        <Logo colour={"dark"} />
        <div className="flex space-x-5 items-center ml-0 md:ml-auto my-5 md:my-0">
          <TwitterShareButton url={data.site.siteMetadata.siteUrl} title={data.site.siteMetadata.title} via={data.site.siteMetadata.twitterHandle} related={data.site.siteMetadata.twitterHandle}>
            <SocialIcons type="twitter" colour="dark" />
          </TwitterShareButton>
          <FacebookShareButton url={data.site.siteMetadata.siteUrl} quote={data.site.siteMetadata.description}>
            <SocialIcons type="facebook" colour="dark" />
          </FacebookShareButton>
          <LinkedinShareButton url={data.site.siteMetadata.siteUrl} title={data.site.siteMetadata.title} summary={data.site.siteMetadata.description} source="Upgraded Points">
            <SocialIcons type="linkedin" colour="dark" />
          </LinkedinShareButton>
        </div>
      </div>
    </footer>
  );
};
