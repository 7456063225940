import React from "react"
import SunsetPatternTop from "../images/svg/sunset-pattern-top.svg"

const Section6 = ({ pageType }) => {
    return (
        <div
            id="section6"
            className="flex flex-col relative w-full h-auto items-center py-10 mt-0 lg:mt-10 bg-blue relative"
        >
            <SunsetPatternTop />
            <div className="container my-auto w-100 flex flex-row justify-center">
                <div className="w-full max-w-xl text-center my-10">
                    <h3 className="uppercase font-bold text-2xl text-white mb-3">Help your friends and family find the ultimate in-flight pleasures by sharing this index with them.</h3>
                    {/* <a href="https://upgradedpoints.com" className="inline-block button uppercase text-white border-2 border-white mt-5 px-8 py-3">Learn more</a> */}
                </div>
            </div>
        </div>
    );
};

export default Section6;