import React, { useRef } from "react";
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Swiper, SwiperSlide } from "swiper/react";
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import ArrowButton from '@material-ui/icons/ArrowRightAlt';

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";
import "swiper/css/navigation";
import "swiper/css/scrollbar";

import { EffectCards, Navigation, Scrollbar } from "swiper";

export default function App({ type, top5 }) {
    const navigationPrevRef = useRef(null)
    const navigationNextRef = useRef(null)

    const images = useStaticQuery(
        graphql`
          query {
            alaska: allFile(filter: {name: {eq: "alaska-airlines-logo"}}) {
                edges {
                    node {
                        publicURL
                    }
                }
            }
            american: allFile(filter: {name: {eq: "american-airlines-logo"}}) {
                edges {
                    node {
                        publicURL
                    }
                }
            }
            delta: allFile(filter: {name: {eq: "delta-logo"}}) {
                edges {
                    node {
                        publicURL
                    }
                }
            }
            frontier: allFile(filter: {name: {eq: "frontier-logo"}}) {
                edges {
                    node {
                        publicURL
                    }
                }
            }
            hawaiian: allFile(filter: {name: {eq: "hawaiian-airlines-logo"}}) {
                edges {
                    node {
                        publicURL
                    }
                }
            }
            jetblue: allFile(filter: {name: {eq: "jetblue-logo"}}) {
                edges {
                    node {
                        publicURL
                    }
                }
            }
            southwest: allFile(filter: {name: {eq: "southwest-logo"}}) {
                edges {
                    node {
                        publicURL
                    }
                }
            }
            spirit: allFile(filter: {name: {eq: "spirit-logo"}}) {
                edges {
                    node {
                        publicURL
                    }
                }
            }
            suncountry: allFile(filter: {name: {eq: "sun-country-airlines-logo"}}) {
                edges {
                    node {
                        publicURL
                    }
                }
            }
            united: allFile(filter: {name: {eq: "united-airlines-logo"}}) {
                edges {
                    node {
                        publicURL
                    }
                }
            }
            comfort: allFile(filter: {name: {regex: "/comfort-/"}}) {
                edges {
                    node {
                        childImageSharp {
                            gatsbyImageData(
                              width: 250
                              placeholder: BLURRED
                              formats: [AUTO, WEBP, AVIF]
                              layout: CONSTRAINED
                            )
                        }
                    }
                }
            }
            entertainment: allFile(filter: {name: {regex: "/entertainment-/"}}) {
                edges {
                    node {
                        childImageSharp {
                            gatsbyImageData(
                              width: 250
                              placeholder: BLURRED
                              formats: [AUTO, WEBP, AVIF]
                              layout: CONSTRAINED
                            )
                        }
                    }
                }
            }
            refreshments: allFile(filter: {name: {regex: "/refreshments-/"}}) {
                edges {
                    node {
                        childImageSharp {
                            gatsbyImageData(
                              width: 250
                              placeholder: BLURRED
                              formats: [AUTO, WEBP, AVIF]
                              layout: CONSTRAINED
                            )
                        }
                    }
                }
            }
            covid: allFile(filter: {name: {regex: "/covid-/"}}) {
                edges {
                    node {
                        childImageSharp {
                            gatsbyImageData(
                              width: 250
                              placeholder: BLURRED
                              formats: [AUTO, WEBP, AVIF]
                              layout: CONSTRAINED
                            )
                        }
                    }
                }
            }
          }
        `
    )

    let filter = '';

    let data = top5.map((value, index) => {
        value.number = '0' + (index + 1);

        switch (type) {
            case ("Comfort"):
                filter = "comfort";
                value.image = getImage(images.comfort.edges[index].node)
                break;
            case ("Entertainment"):
                filter = "entertainment";
                value.image = getImage(images.entertainment.edges[index].node)
                break;
            case ("Refreshments"):
                filter = "drink";
                value.image = getImage(images.refreshments.edges[index].node)
                break;
            case ("Covid safe"):
                filter = "covid";
                value.image = getImage(images.covid.edges[index].node)
                break;
        }

        switch (value.airline) {
            case ("Alaska Airlines"):
                value.airlineImage = images.alaska?.edges[0]?.node?.publicURL
                break;
            case ("American Airlines"):
                value.airlineImage = images.american?.edges[0]?.node?.publicURL
                break;
            case ("Delta"):
                value.airlineImage = images.delta?.edges[0]?.node?.publicURL
                break;
            case ("Frontier"):
                value.airlineImage = images.frontier?.edges[0]?.node?.publicURL
                break;
            case ("Hawaiian Airlines"):
                value.airlineImage = images.hawaiian?.edges[0]?.node?.publicURL
                break;
            case ("JetBlue"):
                value.airlineImage = images.jetblue?.edges[0]?.node?.publicURL
                break;
            case ("Southwest"):
                value.airlineImage = images.southwest?.edges[0]?.node?.publicURL
                break;
            case ("Spirit"):
                value.airlineImage = images.spirit?.edges[0]?.node?.publicURL
                break;
            case ("Sun Country"):
                value.airlineImage = images.suncountry?.edges[0]?.node?.publicURL
                break;
            case ("United"):
                value.airlineImage = images.united?.edges[0]?.node?.publicURL
                break;
        }

        return value;
    })

    return (
        <>
            <Swiper
                effect={"cards"}
                grabCursor={true}
                scrollbar={{
                    hide: false,
                }}
                modules={[EffectCards, Navigation, Scrollbar]}
                className="cardsSwiper"
                navigation={{
                    prevEl: navigationPrevRef.current,
                    nextEl: navigationNextRef.current,
                }}
                loop
            >
                {data.map((slide) => {
                    return (<SwiperSlide>
                        <div className="clip-circle absolute top-0 right-10"></div>
                        <div className={`flex justify-center pt-10 absolute left-10 h-full w-auto bg-up-blue text-white font-oswald font-light text-2xl px-4`}>
                            #{slide.number}
                        </div>

                        <div className="w-3/4 h-full grid grid-cols-3 pl-5">
                            <div className="col-span-2 flex flex-col justify-center items-start">
                                <img src={slide.airlineImage} alt="Airline Logo" style={{
                                    height: "30px",
                                    width: "auto",
                                    objectFit: "contain"
                                }} />

                                <span className="text-xl mt-5 font-bold">{slide.airline}</span>
                                <span className="text-sm mt-1 font-light">{slide[filter]}/100</span>
                            </div>
                            <div className="col-span-1 image">
                                <GatsbyImage image={slide.image} alt={slide.name} height="300" objectFit="cover" objectPosition={"50% 50%"} />
                            </div>
                        </div>

                    </SwiperSlide>)
                })}
                <div className='flex justify-center gap-x-3 mt-5'>
                    <button ref={navigationPrevRef} className='bg-up-blue text-white py-2 px-2 rounded-full transform rotate-180 leading-none'>
                        <ArrowButton />
                    </button>
                    <button ref={navigationNextRef} className='bg-up-blue text-white py-2 px-2 rounded-full leading-none'>
                        <ArrowButton />
                    </button>
                </div>
            </Swiper>
        </>
    );
}