import React, { useEffect } from "react";
import { StaticImage } from "gatsby-plugin-image";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import PlaneWindow from "../images/svg/plane-window.svg";
import Chair from "../images/svg/chair.svg";
import ChairMobile from "../images/svg/chair-mobile.svg";
import FlightIndexLogo from "../images/svg/the-in-flight-pleasure-index-campaign-logo.svg";
import FlightIndexLogoWhite from "../images/svg/the-in-flight-pleasure-index-campaign-logo-white.svg";
import { TimelineLite } from "gsap/all";
import WindowPatternBottom from "../images/svg/window-pattern-bottom.svg"


const Hero2 = ({ back, isMobile, page, pageType }) => {
    return (
        <div
            id="hero"
            className="flex flex-col relative w-full h-full items-center py-16 lg:py-0 relative"
        >
            {pageType == 'economy' ? (
                <StaticImage
                    alt="Sky"
                    src={"../images/hero-bg.jpg"}
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    objectFit="cover"
                    objectPosition="50% 50%"
                    layout="constrained"
                    width="1600"
                    placeholder="blurred"
                    className="absolute w-full h-full left-0 top-0"
                    style={{
                        position: 'absolute'
                    }}
                />
            ) : (
                <StaticImage
                    alt="Sky"
                    src={"../images/hero-bg-first-class.jpg"}
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    objectFit="cover"
                    objectPosition="50% 50%"
                    layout="constrained"
                    width="1600"
                    placeholder="blurred"
                    className="absolute w-full h-full left-0 top-0"
                    style={{
                        position: 'absolute'
                    }}
                />
            )}
            <div className="container-xl w-full my-auto flex flex-row justify-center px-6 sm:px-12 py-24 gap-x-10 lg:gap-x-20">
                <div className="w-full lg:w-1/2 flex flex-col items-center justify-center mx-auto text-center">
                    {pageType == 'economy' ? (
                        <FlightIndexLogo id="logo" />
                    ) : (
                        <FlightIndexLogoWhite id="logo" />
                    )
                    }
                    <h1 className={`uppercase text-lg lg:text-xl mt-10 z-10 backdrop-filter backdrop-blur-sm bg-opacity-30 bg-white py-4 lg:py-6 px-12 lg:px-24 rounded-full ${pageType == 'economy' ? 'text-up-blue' : 'text-white'}`}>
                        {pageType == 'economy' ? 'The best economy experiences' : 'The best first-class experiences'}
                    </h1>
                </div>

            </div>
            <WindowPatternBottom />
        </div>
    );
};

export default Hero2;