import React from "react"

const Section5 = ({ pageType }) => {
    return (
        <div
            id="section5"
            className="flex flex-col relative w-full h-auto items-center py-10 mt-10"
        >
            <div className="container my-auto w-100 flex flex-col lg:flex-row justify-center gap-x-10">
                <div className="w-full max-w-xl text-center">
                    <h2 className="uppercase font-bold text-xl lg:text-3xl text-black mb-3">Methodology</h2>
                    <p className="text-sm font-regular">
                        The index is made up of 6 categories - entertainment, amenities, comfort, COVID-19 safe, food and drinks. Each of the airlines were ranked out of 100 for their performance under each metric, with a high score representing a good measure on the metric. For example a high score is given to the airline with the most legroom.
                    </p>
                    <p className="text-sm font-regular">The scores for each metric were averaged for each category to give a total score for each category. The category scores were then averaged to give a total score for each airline.</p>
                </div>
            </div>
        </div>
    );
};

export default Section5;